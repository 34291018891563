import { useEffect, useMemo, useRef, useState } from 'react';
import { getTextDomWidth } from '@/utils/utils';
import styles from './Carousel.module.css';

interface CarouselProps {
  str: string;
  stokeStr: string;
  hasBg?: boolean;
  classnames?: string;
  fontSize: number;
}
const Carousel = ({ str, stokeStr, hasBg = true, classnames, fontSize }: CarouselProps) => {
  const [domCount, setDomCount] = useState(0);
  const [mounted, setMounted] = useState(false);
  const frontRef = useRef<HTMLDivElement>(null);
  const backRef = useRef<HTMLDivElement>(null);

  const renderDoms = useMemo(() => {
    if (!mounted) return [];
    return new Array(domCount).fill(0);
  }, [domCount, mounted]);

  useEffect(() => {
    if (frontRef.current && backRef.current && mounted) {
      backRef.current.classList.add('carousel-back');
      frontRef.current.classList.add('carousel-front');
    }
  }, [mounted]);

  useEffect(() => {
    function getDomCount() {
      const bodyWidth = document.body.clientWidth;
      const textWidth = getTextDomWidth({
        str: str + stokeStr,
        fontSize: fontSize,
      });
      if (bodyWidth > 1200) {
        setDomCount(Math.ceil(bodyWidth / textWidth));
      } else {
        setDomCount(Math.ceil(1200 / textWidth));
      }
    }
    getDomCount();
    setMounted(true);

    window.addEventListener('resize', getDomCount);
    return () => {
      window.removeEventListener('resize', getDomCount);
    };
  }, [str, stokeStr, fontSize]);

  return (
    <section className="pointer-events-none relative z-10 w-screen">
      <div
        className={`relative z-10 w-screen min-w-[1200px] overflow-hidden ${
          hasBg ? 'bg-active-color' : ''
        } ${classnames}`}
      >
        <div className='h-[10px] w-[100vw]'></div>
        <span ref={frontRef} className='pl-[.3em]'>
          {renderDoms.map((_, index) => {
            return (
              <span className="tracking-[0.04rem]" key={index}>
                <span className="text-white">{str}</span>
                <span className={styles['stroke-str']}>{stokeStr}</span>
              </span>
            );
          })}
        </span>
        <span ref={backRef} className='pl-[.3em]'>
          {renderDoms.map((_, index) => {
            return (
              <span className="tracking-[0.04rem]" key={index}>
                <span className="text-white">{str + ' '}</span>
                <span className={styles['stroke-str']}>{stokeStr + ' '}</span>
              </span>
            );
          })}
        </span>
      </div>
      {hasBg && (
        <div>
          <div className="absolute top-0 left-0 h-[80px] w-[100vw] rotate-[-0.5deg] bg-active-color opacity-60"></div>
          <div className="absolute top-0 left-0 h-[80px] w-[100vw] rotate-[-1deg] bg-active-color opacity-40"></div>
        </div>
      )}
    </section>
  );
};

export default Carousel;
