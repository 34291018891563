import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { Variants } from 'framer-motion';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import cls from 'classnames';
import Carousel from '@/components/common/Carousel';
import { StarIcon, ArrowIcon, AimIcon } from '@/components/common/Icon';
import { formatSuperscript } from '@/components/common/Intl';
import { useThrottledCallback } from 'use-debounce';
import { useApp } from '@/provider/App';
import styles from './Case.module.css';

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.5,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const images = [
  {
    img: '/overseacdn/passinArk/homepage/img/home/case1.png',
    title: 'case.case1.title',
    content: 'case.case1.content',
    url: '/case/dsand',
  },
  {
    img: '/overseacdn/passinArk/homepage/img/home/case2.png',
    title: 'case.case2.title',
    content: 'case.case2.content',
    url: '/case/SoKrispy',
  },
  {
    img: '/overseacdn/passinArk/homepage/img/home/case3.png',
    title: 'case.case3.title',
    content: 'case.case3.content',
    url: '/case/TrueMobster',
  },
  {
    img: '/overseacdn/passinArk/homepage/img/home/case4.png',
    title: 'case.case4.title',
    content: 'case.case4.content',
    url: '/case/HuusKnife',
  },
  {
    img: '/overseacdn/passinArk/homepage/img/home/case5.png',
    title: 'case.case5.title',
    content: 'case.case5.content',
    url: '/case/RobLandes',
  },
  {
    img: '/overseacdn/passinArk/homepage/img/home/case6.png',
    title: 'case.case6.title',
    content: 'case.case6.content',
    url: '/case/Dayum',
  },
];

const autoPlayInterval = 6000; /** ms */

const Case = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const swiperPlay = useRef<NodeJS.Timeout>();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerIsInView = useInView(containerRef, { once: true });
  const [showButton, setShowButton] = useState<boolean>(false);
  const { pathname } = useRouter();

  const { setHistory } = useApp();

  const paginate = (newDirection: number) => {
    setPage(([page, direction]) => {
      return [page + newDirection, newDirection];
    });
  };

  const getImageIndex = (page: number): number => {
    return page < 0
      ? page % images.length === 0
        ? page % images.length
        : (page % images.length) + images.length
      : page % images.length;
  };

  const throttlePaginateCb = useThrottledCallback(paginate, 20);

  useEffect(() => {
    if (!containerIsInView) return;
    const swiperStart = () => {
      swiperPlay.current && clearTimeout(swiperPlay.current);
      swiperPlay.current = setTimeout(() => {
        paginate(1);
      }, autoPlayInterval);
    };
    swiperStart();
    return () => {
      swiperPlay.current && clearTimeout(swiperPlay.current);
    };
  }, [containerIsInView, page]);

  return (
    <motion.section
      className="relative mb-[245px] w-[100vw] min-w-[1200px]"
      variants={listVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.div
        className="mx-auto mb-[40px] flex flex-nowrap justify-center"
        variants={itemVariants}
        transition={{
          duration: 0.5,
        }}
      >
        <span className="relative text-[60px]">
          <FormattedMessage id="home.case.title" />
          <span className="big-zoom absolute top-[10px] right-[-30px] z-10">
            <StarIcon />
          </span>
          <span className="small-zoom absolute top-[-8px] right-[-48px]  z-10">
            <StarIcon />
          </span>
          <span className="absolute top-[-50px] left-[-30px] scale-50">
            <AimIcon />
          </span>
        </span>
      </motion.div>
      <motion.div
        variants={itemVariants}
        transition={{
          duration: 0.5,
        }}
      >
        <Carousel
          str=""
          stokeStr="LET'S THRIVE TOGETHER "
          hasBg={false}
          classnames={'text-[100px] h-[150px] select-none'}
          fontSize={100}
        />
      </motion.div>
      <motion.div
        variants={itemVariants}
        transition={{
          duration: 0.5,
        }}
      >
        <AnimatePresence initial={false} custom={direction}>
          <div className='flex items-start'>
            <div
              className=" mt-[-40px] flex flex-nowrap items-end"
              ref={containerRef}
              onMouseEnter={() => {
                setShowButton(true);
              }}
              onMouseLeave={() => {
                setShowButton(false);
              }}
            >
              <motion.span
                className={cls(
                  'flex justify-center items-center cursor-pointer select-none',
                  'basis-[30%] -translate-x-[15%] -translate-y-[15%] opacity-40 scale-[1.3]',
                  'overflow-hidden custom-clip-path',
                )}
              >
                <Link
                  href={images[getImageIndex(page - 1)].url}
                  onClick={() => {
                    if (pathname === '/') {
                      setHistory('/');
                    }
                  }}
                >
                  <Image
                    src={images[getImageIndex(page - 1)].img}
                    className="duration-150 hover:scale-125"
                    width={800}
                    height={450}
                    alt=""
                  />
                </Link>
              </motion.span>
              <span className="relative mx-[45px] inline-block basis-2/5 cursor-pointer">
                <Link
                  href={images[getImageIndex(page)].url}
                  onClick={() => {
                    if (pathname === '/') {
                      setHistory('/');
                    }
                  }}
                  className="inline-block custom-clip-path"
                >
                  <Image
                    src={images[getImageIndex(page)].img}
                    className="duration-150 hover:scale-125"
                    width={800}
                    height={450}
                    alt=""
                  />
                </Link>
                {showButton && (
                  <span
                    onClick={() => throttlePaginateCb(-1)}
                    className="absolute top-1/2 -left-1/3 z-50 flex h-[60px] w-[60px] rotate-180 cursor-pointer items-center justify-center rounded-full bg-[#992F1F]"
                  >
                    <ArrowIcon />
                  </span>
                )}
                {showButton && (
                  <span
                    onClick={() => throttlePaginateCb(1)}
                    className="absolute top-1/2 -right-1/3 z-50 flex h-[60px] w-[60px] cursor-pointer items-center justify-center rounded-full bg-[#992F1F]"
                  >
                    <ArrowIcon />
                  </span>
                )}
                <h2 className={styles.caseTitle} style={{ maxWidth: 'calc(40vw - 90px)' }}>
                  <FormattedMessage
                    id={images[getImageIndex(page)].title}
                    values={{
                      super: formatSuperscript,
                    }}
                  />
                </h2>
              </span>
              <Link
                className={cls(
                  'flex justify-center items-center cursor-pointer select-none',
                  'basis-[30%] translate-x-[15%] -translate-y-[15%] opacity-40 scale-[1.3]',
                  'overflow-hidden custom-clip-path',
                )}
                href={images[getImageIndex(page + 1)].url}
                onClick={() => {
                  if (pathname === '/') {
                    setHistory('/');
                  }
                }}
              >
                <Image
                  src={images[getImageIndex(page + 1)].img}
                  className="duration-150 hover:scale-125"
                  width={800}
                  height={450}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </AnimatePresence>
      </motion.div>

      <span className="absolute bottom-[-50px] right-[50%] translate-x-[380px] scale-75">
        <AimIcon />
      </span>
    </motion.section>
  );
};

export default Case;
