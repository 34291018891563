interface CarouselProps {
  content: string;
  classnames?: string;
}
const ShortCarousel = ({ content, classnames }: CarouselProps) => {
  return (
    <div className={`${classnames} overflow-hidden text-white`}>
      <span className="short-carousel-front">{content}</span>
      <span className="short-carousel-back">{content}</span>
    </div>
  );
};

export default ShortCarousel;
