import { CONVERSION_LABEL, GA_ADS_ID } from '@/constants/common';
import { addGtagEvent, gaEventName } from '@/utils/addGA';
import { useEffect } from 'react';

interface useGAPageViewProps {
  name: string;
  depend?: string;
}

const useGAPageView = ({ name, depend = '' }: useGAPageViewProps) => {
  useEffect(() => {
    addGtagEvent({
      event: gaEventName.PAGE_VIEW,
      options: {
        page_title: name,
        page_location: window.location.href,
        page_from: 'passionArk',
      },
    });
    addGtagEvent({
      event: gaEventName.UNIQUE_VISITOR,
      options: {
        page_title: name,
        page_location: window.location.href,
        page_from: 'passionArk',
      },
      isUV: true,
      uvId: name,
    });
    //ga ads转化埋点
    name === 'passionArk_homepage' &&
      gtag('event', 'conversion', { send_to: `${GA_ADS_ID}/${CONVERSION_LABEL}` });
  }, [depend]);
};

export default useGAPageView;
